<template>
  <div id="impressum" class="mt-5 text-container">
    <h1>Impressum</h1>

    <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
    <p>
      AWH Apothekenberatung Wigand-Heppelmann GmbH<br />
      Reiherstra&szlig;e 6<br />
      59071 Hamm
    </p>

    <p>
      Handelsregister: HRB 7019<br />
      Registergericht: Amtsgericht Hamm
    </p>

    <p>
      <strong>Vertreten durch:</strong><br />
      Hans-Eberhard Heppelmann<br />
      Brigitte Wigand-Heppelmann
    </p>

    <h2>Kontakt</h2>
    <p>
      Telefon: 02381 870 679<br />
      Telefax: 02381 870 677<br />
      E-Mail: info@awh-beratung.de
    </p>

    <h2>Umsatzsteuer-ID</h2>
    <p>
      Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a
      Umsatzsteuergesetz:<br />
      DE 275537926
    </p>

    <h2>
      Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
    </h2>
    <p>
      Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor
      einer Verbraucherschlichtungsstelle teilzunehmen.
    </p>

    <p>
      Quelle:
      <a href="https://www.e-recht24.de/impressum-generator.html"
        >https://www.e-recht24.de/impressum-generator.html</a
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "Impressum",
  metaInfo: {
    title: "Impressum",
  },
};
</script>

<style scoped>
#impressum {
  text-align: left;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
</style>